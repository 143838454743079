// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contract-js": () => import("./../../../src/pages/contract.js" /* webpackChunkName: "component---src-pages-contract-js" */),
  "component---src-pages-gtc-js": () => import("./../../../src/pages/gtc.js" /* webpackChunkName: "component---src-pages-gtc-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introduction-js": () => import("./../../../src/pages/introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-price-index-js": () => import("./../../../src/pages/price/index.js" /* webpackChunkName: "component---src-pages-price-index-js" */),
  "component---src-pages-price-order-js": () => import("./../../../src/pages/price/order.js" /* webpackChunkName: "component---src-pages-price-order-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-usercenter-index-js": () => import("./../../../src/pages/usercenter/index.js" /* webpackChunkName: "component---src-pages-usercenter-index-js" */)
}

